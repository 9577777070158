@import '../main.scss';

.about__container{
    @extend %container;
}

.about__wrapper {
    @extend %wrapper;
    display: grid;
    gap: 6.4rem;
    
   
    @include media('<=phone'){
        width: 100%;
    }
}

.about__intro{
    max-width: 76.8rem;
    // width: 100%;
    

    @include media('<=phone'){
        max-width: 38.3rem;
    }
}

.about__intro__tag{
    @extend %text-md;
    font-weight: 600;
    color: $blue-500;

    @include media('<=phone'){
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

.about__intro__text{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;
    margin-top: 1.2rem;

    @include media('<=phone'){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.about__intro__supporting{
    @extend %text-lg;
    color: $dark-300; 
    margin-top: 2rem;
    font-weight: 400;

    @include media('<=phone'){
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.about__content{
    display: grid;
    gap: 4.8rem;
    width: 100%;
}

.about__content__one{
  display: flex;
    gap: 6.4rem;
  width: 100%;
  align-items: flex-start;


  @include media('<=phone'){
    display: grid;
  }

  div{
    max-width: 57.6rem;
    display: grid;
  
    gap: 4rem;

    h1{
        @extend %display-sm;
        color: $dark-500;
        font-weight: 600;

        @include media('<=phone'){
            font-size: 2.4rem;
            line-height: 3.2rem;
        }
    }

    p{
        @extend %text-lg;
        color: $dark-300;
        font-weight: 400;
        

        @include media('<=phone'){
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }
  }

    figure {
        width: 57.6rem;
        height: 56rem;
        height: auto;

        @include media('<=phone'){
            width: 34.3rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}