@import '../main.scss';

.copy__experts__dark__container{
    @extend %container;
    background-color: $dark-600;
}

.copy__experts__dark__wrapper {
    @extend %wrapper;
    display: grid;
    gap: 6.4rem;
    
   
    @include media('<=phone'){
        width: 100%;
    }
}

.copy__experts__dark__intro{
    max-width: 76.8rem;
    // width: 100%;
    

    @include media('<=phone'){
        max-width: 38.3rem;
    }
}

.copy__experts__dark__intro__tag{
    @extend %text-md;
    font-weight: 600;
    color: $blue-500;

    @include media('<=phone'){
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

.copy__experts__dark__intro__text{
    @extend %display-md;
    color: $white;
    font-weight: 600;
    margin-top: 1.2rem;

    @include media('<=phone'){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.copy__experts__dark__intro__supporting{
    @extend %text-lg;
    color: $dark-100; 
    margin-top: 2rem;
    font-weight: 400;

    @include media('<=phone'){
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.copy__experts__dark__content{
    display: grid;
    gap: 4.8rem;
    width: 100%;
}

.copy__experts__dark__content__one{
    display: grid;
    gap: 3.2rem;
    width: 100%;
    align-items: flex-start;

    h1{
        @extend %display-xs;
        color: $white;
        font-weight: 600;
        text-transform: capitalize;

        @include media('<=phone'){
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }

    p{
        @extend %text-lg;
        color: $dark-50;
        font-weight: 400;
        

        @include media('<=phone'){
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }

  
    div{
      max-width: 57.6rem;
      display: grid;
    
      gap: 4rem;
  
      h1{
          @extend %display-sm;
          color: $white;
          font-weight: 600;
  
          @include media('<=phone'){
              font-size: 2.4rem;
              line-height: 3.2rem;
          }
      }
  
      p{
          @extend %text-lg;
          color: $dark-50;
          font-weight: 400;
          
  
          @include media('<=phone'){
              font-size: 1.6rem;
              line-height: 2.4rem;
          }
      }
    }
  
  }