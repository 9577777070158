@import '../main.scss';

.faq__container{
    @extend %container;
}

.faq__wrapper {
    @extend %wrapper;
    display: grid;
    gap: 6.4rem;
    
   
    @include media('<=phone'){
        width: 100%;
    }
}

.faq__intro{
    max-width: 76.8rem;
    // width: 100%;
    

    @include media('<=phone'){
        max-width: 38.3rem;
    }
}

.faq__intro__title{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;
    margin-top: 1.2rem;

    @include media('<=phone'){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.faq__intro__text{
    @extend %text-lg;
    color: $dark-300; 
    margin-top: 2rem;
    font-weight: 400;

    @include media('<=phone'){
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.faq__content{
    display: grid;
    gap: 3.2rem;
    grid-template-columns: auto auto auto;

    @include media('<=phone'){
        grid-template-columns: auto;
    }
}

.faq__question{
    max-width: 38.4rem;
    display: grid;
    gap: 0.8rem;

    @include media('<=phone'){
        
    }

    h1{
        @extend %text-lg; 
        font-weight: 500;
        color: $dark-400;
    }
    
    p{
        @extend %text-md; 
        color: $dark-300;
    }
}



