@import '../main.scss';


.pricing__one__container{
    padding: 9.6rem 8rem;

    @include media ('<=tablet') {
        padding: 3.2rem 0;
    }

    @include media ('<=phone') {
        padding: 3.2rem 0;
    }

}

.pricing__one__wrapper{
    max-width: 128.0rem;
    padding: 0 3.2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include media ('<=tablet') {
        display: grid;
        width: 100%;
        padding: 0 1.6rem;
        gap: 4.8rem;
        place-content: center;
    }
    
    @include media ('<=phone') {
        display: grid;
        width: 100%;
        padding: 0 1.6rem;
        gap: 4.8rem;
        place-content: center;
    }
}

.pricing__one__text {
    display: grid;
    gap: 1.2rem;
    max-width: 44.8rem;
    height: max-content;
    width: 100%
}

.pricing__one__section__label {
    @extend %text-md;
    color: $blue-500;
    font-weight: 600;
    font-size: 1.4rem;
}

.pricing__one__section__title{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;
    font-size: 3rem;
}

.pricing__one__section__supporting__text {
    @extend %text-lg;
    font-weight: 400;
    color: $dark-400;
    margin-top: 0.8rem;
}

.pricing__one__cards{
    display: flex;
    gap: 3.2rem;

    @include media('<=tablet'){
        max-width: 100%;
    }

    
    @include media ('<=phone') {
        width: 100%;
        display: grid;
    }
}

.pricing__one__card{
    background: $white;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    width: 33.6rem;
    display: grid;
    border: 0.1rem solid $dark-50;
    border-radius: 1.6rem;

    @include media('<=tablet') {
        min-width: 33.6rem * 1.221;
        width: auto;
    }

    @include media('<=phone') {
        // max-width: 34.3rem;
        width: auto;
    }
}

.pricing__one__card__mid, .pricing__one__card__bottom{
    padding: 3.2rem;
}

.pricing__one__card__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 3.2rem;
    padding-top: 3.2rem;
    gap: 2rem;

    span {
        display: grid;
        justify-items: center;
    }
}

.pricing__one__card__name{
    @extend %text-xl;
    color: $blue-500;
    font-weight: 600;
   
}

.pricing__one__card__price {
    @extend %display-lg;
    font-weight: 600;
    color: $dark-500;
    margin-top: 0.8rem;
}


.pricing__one__feature__list {
    display: grid;
    gap: 1.6rem;
}

.pricing__one__feature__item {
    display: flex;
    gap: 1.2rem;
    align-items: center;

    p {
        @extend %text-md;
        color: $dark-300;
    }
}

.pricing__one__card__button{
    @extend %text-md;
    color: $white;
    font-weight: 600;
    background: $blue-500;
    padding: 1.2rem 2rem;
    width: 100%;
    border: none;
    border-radius: 0.8rem;
}