@import '../main.scss';

.testimonials__one__container {
    padding: 0rem 8rem;
    padding-bottom: 9.6rem;

    @include media('<=phone'){
        padding: 0rem 1.6rem;
        padding-bottom: 6.4rem;
    }
}

.testimonial__one__content{
    background-color: $dark-600;
    // width: auto;
    // height: 91.9rem;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include media("<=phone") {
        // border-radius: 0;
        max-width: unset;
        width: 100%;
        height: 100%;
        display: grid;
    }
}

.testimonials__one__wrapper {
    background-color: $dark-600;
    // display: flex;
    // border-radius: 2.4rem;
    max-width: 121.6rem;
    width: 100%;
    // justify-content: space-between;
    

    @include media("<=phone") {
        // border-radius: 0;
        // max-width: unset;
        // width: 100%;
        // display: grid;
    }
}

.testimonials__one__greeting{
    @extend %display-xs;
    color: $blue-500;
}

.testimonials__one__text{
    width: 60.8rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding: 6.4rem;

    @include media("<=phone") {
        max-width: 34.3rem;
        width: 34.3rem;
        padding: 4rem 2.4rem;
    }
}

.testimonials__one__review {
    @extend %display-xs;
    font-weight: 500;
    color: $white;
}

.testimonials__one__user__name {
    @extend %text-lg;
    font-weight: 600;
    color: $white;
    margin-top: 3rem;
}

.testimonials__one__user__title {
    @extend %text-md;
    font-weight: 600;
    color: $dark-50;
}

.testimonials__one__figure {
    max-width: 48rem;
    width: 100%;
    // height: 30rem;
    position: absolute;

    @include media("<=phone") {
        max-width: unset;
        width: 100%;
        height: 100%;
    }
    
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 2.4rem;
        border-top-right-radius: 2.4rem;
        
           
        @include media("<=phone") {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 2.4rem;
            border-bottom-right-radius: 2.4rem;
        }
    }

}



.testimonials__one__icons{
    display: flex;
    gap: 1.6rem;
}

.testimonials__one__icon {
    width: 1rem;
    height: 1rem;
    background: $dark-50;
    border-radius: 0.6rem;
}

.testimonials__one__active {
    width: 1rem;
    height: 1rem;
    background: $white;
    border-radius: 0.6rem;
}

.testimonials__one__desktop{
    

    @include media ('<=phone') {
        // display: none;
    }
}