@import '../main.scss';

.features__five__container{
    padding: 9.6rem 8rem;

    @include media ("<=phone"){
        padding: 6.4rem 0;
    }
}

.features__five__wrapper{
    padding: 0 3.2rem;

    @include media ("<=phone"){
        padding: 0;
    }
}

.features__five__top{
    display: grid;
    gap: 1.2rem;
    max-width: 128rem;
    width: 100%;
    text-align: left;

    @include media ("<=phone"){
        max-width: unset;
        width: auto;
        padding: 0 1.6rem;
    }
}

.features__five__top__label{
    @extend %text-md;
    color: $blue-500;
    max-width: max-content;
    font-weight: 600;
}

.features__five__top__title{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;

    @include media ("<=phone"){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.features__five__top__text {
    @extend %text-xl;
    font-weight: 400;
    color: $dark-300;
    margin-top: 0.8rem;

    @include media ("<=phone"){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.features__five__bottom{
    display: flex;
    justify-content: space-between;
    max-width: 128rem;
    margin-top: 6.4rem;

    @include media ("<=phone"){
        display: grid;
    }
}

.features__five__features{
    display: grid;
    gap: 4.8rem;
    padding: 2.2rem 0;
    height: max-content;

    @include media ("<=phone"){
        padding: 0 1.6rem;
    }
}

.features__five__feature__icon{
    width: 4.8rem;
    height: 4.8rem;
}

.features__five__feature {
    display: flex;
    gap: 1.6rem;
    max-width: 51.2rem;
}

.features__five__feature__title{
    @extend %text-xl;
    font-weight: 600;
    color: $dark-500;
}

.features__five__feature__text {
    @extend %text-md;
    font-weight: 400;
    color: $dark-300;
}


.features__five__image{
    width: 57.6rem;

    @include media ("<=phone"){
        width: 100%;
        margin-top: 4.8rem;
        padding: 0;
    }

    img {
        width: 100%;
    }

   
}