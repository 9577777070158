@import '../main.scss';

.assets__one__container{
    max-height: 61.6rem;
    padding: 0rem 8rem;
    padding-bottom: 9.6rem;
    position: relative;
    overflow: hidden;

    @include media('<=phone'){
        max-height: 71.9rem;
        padding: 0rem;
        padding-bottom: 6.4rem;
    }
}

.assets__one__wrapper{
    display: flex;
    padding-right: 3.2rem;
    width: 100%;
    padding-top: 0.8rem;

    @include media('<=phone'){
        display: grid;
        padding-top: 0rem;
        padding-right: 0rem;
        padding: 0rem 1.6rem;
    }
}

.assets__one__top{
    display: grid;
    height: max-content;
    gap: 3.2rem;
    padding: 10.4rem 3.2rem;

    @include media('<=phone'){
        padding: 0rem;
    }
}

.assets__one__title{
    @extend %display-lg;
    font-weight: 600;
    color: $dark-500;

    @include media('<=phone'){
        font-size: 3rem;
        line-height: 3.8rem;
        max-width: 34.3rem;
    }
}

.assets__one__features{
    display: grid;
    gap: 2rem;
}

.assets__one__feature{
    display: flex;
    gap: 1.2rem;
    align-items: center;

    p{
        @extend %text-lg;
        color: $dark-300;
    }

    img{
        width: 2.8rem;
        height: 2.8rem;
    }
}

.assets__one__buttons{
    display: flex;
    gap: 1.2rem;
    margin-top: 0.8rem;

    @include media('<=phone'){
        display: grid;
    }
}

.assets__one__button__blue{
    @extend %blue-button-small; 
}

.assets__one__button__white{
    @extend %white-button-small; 
    border: 0.1rem solid #D0D5DD;
}


.assets__one__desktop__figure{
    max-width: 69.8rem;
    height: auto;
    max-height: 56rem;
    position: absolute;
    right: -7.2rem;
    bottom: 0;

    img {
        width: 100%;
        height: 100%;
    }

    @include media('<=phone'){
        display: none;
    }
}

.assets__one__mobile__figure{
    display: none;
    max-width: 34.08rem;
    max-height: 22.7rem;
    justify-self: center;
    margin: 6.4rem 0rem;

    img {
        width: 100%;
        height: 100%;
    }


    @include media('<=phone'){
        display: block;
        height: 100%;
        width: 100%;
    }
}