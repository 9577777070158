@import "../main.scss";

.hero__seven__container{
    padding: 9.6rem 8rem;
    background-color: $dark-600;

    @include media('<=phone'){
        padding:  6.4rem 0rem;
    }
}

.hero__seven__wrapper{
    padding: 0rem 3.2rem;
    max-width: 76.8rem;
    text-align: center;
    display: grid;
    justify-items: center;
    margin: 0 auto;

    @include media('<=phone'){
        padding: 0rem 1.6rem;
        text-align: left;
        justify-items: left
    }
}

.hero__seven__tag{
    @extend %text-md;
    color: $dark-50;
    font-weight: 600;

    @include media('<=phone'){
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

.hero__seven__text{
    @extend %display-2xl;
    font-weight: 600;
    color: $white;
    margin-top: 1.2rem;

    @include media('<=phone'){
        font-size: 3.6rem;
        line-height: 4.4rem;
    }
}

.hero__seven__supporting__text{
    @extend %text-xl;
    color: $dark-50;
    font-weight: 400;
    margin-top: 2.4rem;

    @include media('<=phone'){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.hero__seven__buttons{
    gap: 1.2rem;
    display: flex;
    margin-top: 4.8rem;

    @include media('<=phone'){
        display: grid;
        width: 100%
    }
}

.hero__seven__button__blue {
    @extend %hero__button__blue;
    width: max-content;
    justify-self: center;
    height: max-content;

    @include media('<=phone'){
        width: 100%;
        padding: 1.2rem 0rem;
    }
}


.hero__seven__button__white{
    @extend %hero__button__white;
    width: max-content;
    justify-self: center;

    @include media('<=phone'){
        width: 100%;
        padding: 1.2rem 0rem;
    }
}