@import '../main.scss';


.cta__three__container {
    padding: 9.6rem 8rem;

    @include media ('<=phone') {
        padding: 6.4rem 0rem;
    }
}

.cta__three__wrapper{
    display: grid;
    gap: 2rem;
    text-align: center;
    max-width: 76.8rem;
    margin: 0 auto;
    justify-items: center;
    padding: 0rem 3.2rem;

    @include media ('<=phone') {
        padding: 0rem 1.6rem;
    }
}

.cta__three__text{
    @extend %display-md;
    font-weight: 600;
    color: $dark-500;

    @include media('<=phone'){
        font-size: 3.6rem;
        line-height: 4.4rem;
    }
}

.cta__three__supporting__text{
    @extend %text-xl;
    font-weight: 400;
    color: $dark-300;
}

.cta__three__buttons{
    margin-top: 2rem;
    display: flex;
    gap: 1.2rem;

    @include media('<=phone'){
       display: grid;
       width: 100%;
    }
}

.cta__three__button__blue{
    @extend %blue-button-small; 
}

.cta__three__button__white{
    @extend %white-button-small; 
    border: 0.1rem solid #D0D5DD;   
}
