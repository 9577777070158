@import '../main.scss';

.features__three__container{
    display: grid;
    padding: 9.6rem 8rem;
    
    @include media ("<=phone"){
        padding: 6.4rem 1.6rem;
    }
}

.features__three__wrapper {
    display: grid;
    gap: 6.4rem;
    justify-items: center;
}

.features__three__top{
    display: grid;
    gap: 1.6rem;
    max-width: 76.8rem;
    width: 100%;
    text-align: center;
    justify-items: center;

    @include media ("<=phone"){
        max-width: 34.3rem;
        width: auto;
    }
}

.features__three__top__label{
    @extend %text-md;
    color: $blue-500;
    max-width: max-content;
    font-weight: 600;
}

.features__three__top__title{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;

    @include media ("<=phone"){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.features__three__top__text {
    @extend %text-xl;
    font-weight: 400;
    color: $dark-300;

    @include media ("<=phone"){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.features__three__features{
    width: 100%;
    display: grid;
    gap: 3.2rem;
    grid-template-columns: 38.4rem 38.4rem 38.4rem;

    @include media ("<=phone"){
        grid-template-columns: auto;
    }
}

.features__three__checkmark{
    width: 4.8rem;
    height: 4.8rem;
}

.features__three__feature{
    display: grid;
    justify-items: center;
    gap: 0.8rem;
    text-align: center;
    max-width: 38.4rem;
}

.features__three__feature__title{
    @extend %text-xl;
    font-weight: 600;
    color: $dark-500;
    margin-top: 1.2rem;

    @include media('<=phone'){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.features__three__feature__text{
    @extend %text-md;
    color: $dark-300;
}


