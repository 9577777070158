@import '../main.scss';

.footer__six__cta{
    background-color: $dark-600;
    height: 16.6rem;
    position: relative;
    margin-top: 20.2rem;
    display: none;
    
    @include media('<=phone'){
        margin-top: 22.9rem;
    }
}

.footer__six__cta__container{
     position: absolute;
     width: 100%;
     bottom: -1.3rem;
}

.footer__six__container{
    padding: 8rem 6.4rem;
    background-color: $dark-600;
    
    @include media('<=phone'){
        padding: 0rem;
        padding-bottom: 4.8rem;
        padding-top: 2.2rem;
    }
}

.footer__six__wrapper{
    padding: 0 3.2rem;
    display: grid;
    gap: 6.4rem;
}

.footer__six__brand{
    display: grid;
    gap: 3.2rem;
    justify-items: center
}

.footer__six__brand__text{
    @extend %text-md;
    color: $dark-400;
    font-weight: 400;
    display: none;
}

.footer__six__brand__links {
    display: flex;
    gap: 3.2rem;

    @include media('<=phone'){
        display: grid;
        grid-template-columns: auto auto;
        gap: 1.2rem;
    }
}

.footer__six__brand__link{
    @extend %text-md;
    color: $white;
    font-weight: 600;
}

.footer__six__disclaimer{
    @extend %text-md;
    color: $dark-100;
    font-weight: 400;
    // display: none;
}

.footer__six__bottom{
    display: grid;
    gap: 3.2rem;

    hr{
        width: 100%;
        border: none;
        border-top: 1px solid #475467;
        background-color: transparent;
    }
}

.footer__six__reserved{
    @extend %text-md;
    color: $dark-100;
    font-weight: 400;
}

.footer__six__reserved__wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media('<=phone'){
        display: grid;
        gap: 1.6rem;
    }
}

.footer__six__social__links{
    display: flex;
    gap: 2.4rem;
}

.footer__six__social__link{
    width: 2.4rem;
    height: 2.4rem;
}

.extra__links{
    display: flex;
    gap: 1.6rem;

   
}

.extra__link__text{
    @extend %text-md;
    color: $dark-100;
}

