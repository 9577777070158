@import '../main.scss';


.testimonials__two__container {
    padding: 9.6rem 0;
}

.testimonials__two__wrapper {
    display: grid;
    background-color: $white;
    gap: 3.2rem;
    max-width: 121.6rem;
    padding: 0 3.2rem;
    margin: auto;
    justify-items: center;

    @include media ('<=phone'){
        max-width: unset;
        width: 100%;
        padding: 0 1.6rem;
    }
}

.testimonials__two__review {
    @extend %display-lg;
    color: $dark-500;
    font-weight: 600;
    text-align: center;
}

.testimonials__two__user__wrapper {
    display: grid;
    justify-items: center;
    gap: 1.6rem;
}

.testimonials__two__user__name__wrapper {
    display: grid;
    justify-items: center;
}

.testimonials__two__user__name {
    @extend %text-lg;
    font-weight: 600;
    color: $dark-500;
}

.testimonials__two__user__title{
    @extend %text-md;
    font-weight: 400;
    color: $dark-300;
    max-width: max-content;
    margin: 0;
    // margin-top: 0.4rem;
}

.testimonials__two__figure {
    width: 6.4rem;
    height: 6.4rem;

    img {
        width: 100%;
        height: 100%;
    }
}