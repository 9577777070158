@import "../main.scss";

.hero__six__container{
    padding: 9.6rem 8rem;
    background-color: $dark-600;
    position: relative;
    height: 84.1rem;
    overflow: hidden;

    @include media('<=phone'){
        padding:  6.4rem 0rem;
        height: 50.6rem
    }
}

.hero__six__wrapper{
    padding: 9.6rem 3.2rem;
    max-width: 76.8rem;
    text-align: center;
    display: grid;
    justify-items: center;
    margin: 0 auto;
   

    @include media('<=phone'){
        padding: 0rem 1.6rem;
        text-align: left;
        justify-items: left
    }
}

.hero__six__tag{
    @extend %text-md;
    color: $dark-50;
    font-weight: 600;

    @include media('<=phone'){
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

@keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  

.hero__six__text{
    @extend %display-2xl;
    font-weight: 600;
    color: $white;
    transform: scale(0.94);
    animation: scale 1.3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    // margin-top: 1.2rem;
    // display: flex;
    // gap: 8px;
    // flex-wrap: wrap;

    @include media('<=phone'){
        font-size: 3.6rem;
        line-height: 4.4rem;
    }

    span {
        // display: inline-block;
        opacity: 0;
        filter: blur(4px);
      }
      
      span:nth-child(1) {
        animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(2) {
        animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(3) {
        animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(4) {
        animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(5) {
        animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(6) {
        animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(7) {
        animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }



}

.hero__six__supporting__text{
    @extend %text-xl;
    color: $dark-50;
    font-weight: 400;
    margin-top: 2.4rem;
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);

    @include media('<=phone'){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.hero__six__buttons{
    gap: 1.2rem;
    display: flex;
    margin-top: 4.8rem;
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);

    @include media('<=phone'){
        display: grid;
        width: 100%
    }
}

.hero__six__button__blue {
    @extend %hero__button__blue;
    width: max-content;
    justify-self: center;

    @include media('<=phone'){
        width: 100%;
        padding: 1.2rem 0rem;
    }
}


.hero__six__button__white{
    @extend %hero__button__white;
    width: max-content;
    justify-self: center;

    @include media('<=phone'){
        width: 100%;
        padding: 1.2rem 0rem;
    }
}

.hero__six__rainbow__figure{
    position: absolute;
    pointer-events: none;
    user-select: none;
    left: -20rem;
    top: 0;
    // z-index: 1;
    opacity: 0.6;
    
    @include media('<=phone') {
        left: -50rem;
    }
}