@import '../main.scss';

.features__one__wrapper {   
    display: grid;
    justify-items: center;
    padding: 9.6rem 8rem;

    @include media ("<=phone"){
        padding: 0 1.6rem;
        padding-bottom: 6.4rem;
    }
}

.features__one__top{
    display: grid;
    gap: 1.6rem;
    max-width: 76.8rem;
    width: 100%;
    text-align: center;
    justify-items: center
}

.features__one__top__label{
    @extend %text-sm;
    color: $blue-500;
    background-color: $blue-50;
    padding: 0.4rem 1.2rem;
    mix-blend-mode: multiply;
    border-radius: 1.6rem;
    max-width: max-content;
    font-weight: 500;
}

.features__one__top__title{
    @extend %display-md;
    color: $dark-500;
    font-weight: 600;

    @include media ("<=phone"){
        font-size: 3rem;
        line-height: 3.8rem;
    }
}

.features__one__top__text {
    @extend %text-xl;
    font-weight: 400;
    color: $dark-300;

    @include media ("<=phone"){
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

.features__one__mid{
    margin-top: 6.4rem;
}

.features__one__bottom{
    margin-top: 9.6rem;
}

.features__one__mid__figure__desktop{
    max-width: 121.6rem;
    width: 100%;

    img {
        width: 100%;
    }

    @include media ("<=phone"){
        display: none;
    }
}

@keyframes fade-in-img {
    100% {
      opacity: 1;
      filter: blur(0);
      transform: translateY(0);
    }
  }

.features__one__mid__figure__mobile{
    display: none;
    opacity: 0;
    transform: translateY(2rem);
    animation: fade-in-img 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);

    @include media ("<=phone"){
        display: grid;
        width: auto;
    }
}

.features__one__bottom {
    display: flex;
    gap: 3.2rem;

    @include media ("<=phone"){
        display: grid;
        gap: 4rem;
    }
}


.features__one__feature{
    display: grid;
    justify-items: center;
    gap: 0.8rem;
    text-align: center;
    max-width: 38.4rem;
}

.features__one__feature__title{
    @extend %text-xl;
    font-weight: 600;
    color: $dark-500;
    margin-top: 1.2rem;
}

.features__one__feature__text{
    @extend %text-md;
    color: $dark-300;
}